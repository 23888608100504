import { default as indexImHsGWeGZPMeta } from "/home/runner/work/fosa-land/fosa-land/pages/index.vue?macro=true";
import { default as _91inviteID_937ofqG7GNYSMeta } from "/home/runner/work/fosa-land/fosa-land/pages/invite/[inviteID].vue?macro=true";
import { default as cancel4V4unD646jMeta } from "/home/runner/work/fosa-land/fosa-land/pages/pay/cancel.vue?macro=true";
import { default as successJa0dF9LNyVMeta } from "/home/runner/work/fosa-land/fosa-land/pages/pay/success.vue?macro=true";
export default [
  {
    name: indexImHsGWeGZPMeta?.name ?? "index",
    path: indexImHsGWeGZPMeta?.path ?? "/",
    meta: indexImHsGWeGZPMeta || {},
    alias: indexImHsGWeGZPMeta?.alias || [],
    redirect: indexImHsGWeGZPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/fosa-land/fosa-land/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91inviteID_937ofqG7GNYSMeta?.name ?? "invite-inviteID",
    path: _91inviteID_937ofqG7GNYSMeta?.path ?? "/invite/:inviteID()",
    meta: _91inviteID_937ofqG7GNYSMeta || {},
    alias: _91inviteID_937ofqG7GNYSMeta?.alias || [],
    redirect: _91inviteID_937ofqG7GNYSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/fosa-land/fosa-land/pages/invite/[inviteID].vue").then(m => m.default || m)
  },
  {
    name: cancel4V4unD646jMeta?.name ?? "pay-cancel",
    path: cancel4V4unD646jMeta?.path ?? "/pay/cancel",
    meta: cancel4V4unD646jMeta || {},
    alias: cancel4V4unD646jMeta?.alias || [],
    redirect: cancel4V4unD646jMeta?.redirect || undefined,
    component: () => import("/home/runner/work/fosa-land/fosa-land/pages/pay/cancel.vue").then(m => m.default || m)
  },
  {
    name: successJa0dF9LNyVMeta?.name ?? "pay-success",
    path: successJa0dF9LNyVMeta?.path ?? "/pay/success",
    meta: successJa0dF9LNyVMeta || {},
    alias: successJa0dF9LNyVMeta?.alias || [],
    redirect: successJa0dF9LNyVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/fosa-land/fosa-land/pages/pay/success.vue").then(m => m.default || m)
  }
]